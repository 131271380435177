import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import QuotesSvg from "../assets/svg/quotes.svg"
import CircleSvg from "../assets/svg/quote-circle.svg"

import { QuoteBoxMain } from "../styles/common.styles"
import { RevealImage } from "../elements/reveal-image"
import { unoptimzeGatsbyImageSource } from "../helpers/common"

export const QuoteBox = ({ text, name, label, image, signature, smallImage, smallImageMobile, variation = '', quoteFootnote = '' }) => {
  return (
    <QuoteBoxMain className={`${(image || signature) ? 'image-and-sig' : 'text-only'} qb-${variation}`}>

      {smallImageMobile && (
        <div className="sm-image-wrap mobile">
          <RevealImage alt="Brevan Howard Box" image={smallImageMobile} variation={'from-right'} />
        </div>
      )}

      <div className="quote-sig-wrap">
      </div>

      {/* REGULAR */}
      {image && variation !== 'circle' && (
        <div className="quote-img-wrap">
          {<RevealImage alt="Brevan Howard Box" image={image} variation={'from-right'} />}
        </div>
      )}

      {/* CIRCLE */}
      {image && variation === 'circle' && (
        <div className="quote-img-wrap-c">
          {<CircleSvg />}
          <div className="quote-img-inner">{<Img fluid={unoptimzeGatsbyImageSource(image)} objectPosition="center" />}</div>
        </div>
      )}

      <div className="quote-content">
        <div className={`quote-symb ${variation === 'circle' ? 'blue-quote' : ''}`}>
          <QuotesSvg />
        </div>

        <div className="quote-text">
          {text}
        </div>

        {!!quoteFootnote && <div className="quote-footnote">{quoteFootnote}</div>}

        <div className="quote-meta">
          {smallImage &&
            <RevealImage alt="Brevan Howard" image={smallImage} variation={'desktop from-left'} />
          }
          <div className={`quote-name ${smallImage ? 'has-sm-img' : ''}`}>
            {name}
          </div>

          <div className="quote-label">
            {label}
          </div>
        </div>
      </div>
    </QuoteBoxMain>
  )
}